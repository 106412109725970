.app {
    margin: 0px 10px;
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom: 70px;
    overflow-y: auto;
    overflow-x: hidden;
}

.app-center {
    flex-direction: inherit;
    justify-content: center;
}

footer {
    background: #31d2f2;
    text-align: center;
    width: 100%;
    margin-top: 16px;
    position: absolute;
    bottom: 0;
    overflow-x: hidden;
}

footer .side-line {
    border-right: 1px solid #000000;
}

.table_start_session_gender {
    min-width: 105px;
}

.table_button {
    min-width: 35px;
}

.table_buttons {
    min-width: 105px;
}

.btn-pink {
    color: #fff !important;
    background-color: #ef568a !important;
    border-color: #ef568a !important;
}
.btn-pink table {
    color: #fff !important;
}

.player_item {
    border-radius: 0.25rem;
    margin-top: 16px;
    padding: 8px;
    padding-right: 24px;
}

.battle-content {
    position:absolute;
    top:50px;
    left:0;
    right:0;
    bottom: 70px;
    overflow-y: auto;
    overflow-x: hidden;
}

.battle_win_stat {
    position: absolute;
    bottom: 0;
    overflow-x: hidden;
    margin-top: 16px !important;
    border-radius: 0.25rem;
}

.battle_win_stat_button {
    background: #31d2f2 !important;
}

.battle_win_stat .row,
.battle_win_stat .col-6,
.battle_win_stat .col-12 {
    margin-top: 0 !important;;
}

.mod_width {
    text-align-last: center;
}

.form-group-center {
    margin-left: auto;
    margin-right: auto;
}

.loader {
    z-index: 3;
    position: absolute;
    left: 50%;
    top: 10%;

}

.sticky {
    background: white;
    border-radius: 20px;
    border: 1px solid #00BFFF ;
    padding: 5px;
}

.winAlly {
    color: green;
    display: inline;
}
.lossAlly {
    color: red;
    display: inline;
}

.vertical-middle {
    vertical-align: middle;
}

